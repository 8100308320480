<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <div id="kt_content_container" class="container-xxl">
      <div class="card">
        <div class="card-body">
          <div>dashboard verifikasi</div>
        </div>
      </div>
    </div>
  </div>
</template>
